@font-face {
  font-family: american_typewriter;
  src: url(/fonts/AMERICAN_TYPEWRITER_REGULAR.TTF);
}

* {
  box-sizing: border-box;
  list-style-type: none;
}

body {
  margin: 0;
  padding: 0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.main-wrapper {
  /* width: 1140px; */
  width: 80%;
  margin: auto;
  padding: 40px 0;
}

.main-wrapper .ting-logo {
  text-align: center;
  margin: 20px 0 60px 0;
}

.tooltip {
  position: absolute;
  bottom: 13px;
  transition: all 500ms linear;
  right: 10px;
  opacity: 0;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-family: american_typewriter;
  visibility: hidden;
  width: 90px;
  top: 110%;
  /* position: absolute; */
  right: 0%;
  /* margin-left: -60px; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 14px;
  letter-spacing: 1px;
  top: 130%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  top: 110%;
}

.logo-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin: 0px 50px;
}

.logo-section .logo-inside {
  position: relative;
  width: 30%;
  margin: 40px 0;
}

.logo-section .logo-inside:hover .tooltip {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.logo-section p {
  font-family: american_typewriter;
  font-size: 18px;
  color: #000000;
  margin: 0;
  /* padding-top: 15px;
  padding-bottom: 70px; */
}

.logo-section .logo-inside a {
  text-decoration: none;
}

.logo-section .logo-inside .content {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-section .logo-inside .content :nth-child(1) {
  /* margin-left: 20px; */
}

.logo-section .logo-inside .content img {
  cursor: pointer;
  width: 25px;
}

.logo-img {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}

.close {
  font-size: 20px;
}

iframe {
  display: block;
}

.logo-section .card {
  width: 30%;
}

.logo-section .card .pic {
  width: 348px;
  padding: 0px 10px;
}

@media screen and (max-width: 1200px) {
  .logo-section {
    margin: 0px 0px;
  }

  .main-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .logo-section .logo-inside {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .logo-section .logo-inside {
    width: 100%;
  }

  .logo-img {
    min-height: auto;
  }
}

.tab {
}

.tab .button_section {
  /* display: none; */
  display: flex;
  background-color: #fae90c;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

.tab .button_section h5 {
  display: flex;
  justify-content: center;
}

.tab .button_section h5 span {
  color: #000;
  margin: 0 20px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  font-family: american_typewriter;
}

@media screen and (max-width: 767px) {
  .tab .button_section h5 {
    display: block;
    overflow: scroll;
    white-space: nowrap;
    padding: 10px 20px;
  }

  .tab .button_section h5 span {
    margin: 0;
    padding: 10px 13px;
    text-align: center;
    display: inline-block;
    border-bottom: 2px solid transparent;
    font-size: 16px;
  }
}

.tab .button_section .active {
  border-bottom: 2px solid #000;
}

.categories {
  display: none;
}
.categories.active {
  display: block;
}

.filter {
  display: none;
  right: 50px;
  height: 50px;
  text-align: center;
  background-color: #fae90c;
  font-family: american_typewriter;
  font-size: 20px;
  font-weight: 600;
}

.filter option {
}

@media screen and (max-width: 1000px) {
  .filter {
    font-size: 16px;
    width: 100%;
  }
}
